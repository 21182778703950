import {
	Edit,
	TabbedForm,
	TabbedFormTabs,
} from "react-admin"
import { EnigmaCommonData } from "./enigmaCommonData/enigmaCommonData"
import { EnigmaCaption } from "./enigmaCaption/enigmaCaption"
import { EnigmaIntroOutro } from "./enigmaIntroOutro/enigmaIntroOutro"
import { EnigmaClue } from "./enigmaClues/enigmaClue"
import { EnigmaCaptionClue } from "./enigmaClues/enigmaCaptionClue"
import { EnigmaTemporalClue } from "./enigmaClues/enigmaTemporalClue"
import { EnigmaExplanation } from "./enigmaExplanation/enigmaExplanation"
import { EnigmaEvents } from "./enigmaEvents/enigmaEvents"
import { FormToolbar } from "../components/button"
import { Link } from "@mui/material"

export const EnigmaEdit = (props) => {
	return (
		<Edit {...props}>
			<TabbedForm
				tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
				toolbar={<FormToolbar>
					<Link
						marginLeft={2}
						marginRight={1}
						target="_blank"
						href={`${process.env.REACT_APP_FRONTEND_URL.replace(/\/$/, "")}/?nocache=1&debugnews=${props.id}`}
					>
						Vérifier l'état de l'énigme
					</Link>
					<Link
						marginX={1}
						target="_blank"
						href={`${process.env.REACT_APP_FRONTEND_URL.replace(/\/$/, "")}/?nocache=1&fast=1&enigme=${props.id}`}
					>
						Tester l'énigme en jeu (mode rapide)
					</Link>
					<Link
						marginX={1}
						target="_blank"
						href={`${process.env.REACT_APP_FRONTEND_URL.replace(/\/$/, "")}/?nocache=1&enigme=${props.id}`}
					>
						Tester l'énigme en jeu (mode normal)
					</Link>
				</FormToolbar>}
			>
				<EnigmaCommonData {...props} />
				<EnigmaIntroOutro />
				<EnigmaCaption />
				<EnigmaExplanation />
				<EnigmaClue />
				<EnigmaCaptionClue />
				<EnigmaTemporalClue />
				<EnigmaEvents />
			</TabbedForm>
		</Edit>
	)
}
