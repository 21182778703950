import {
	Create,
	TabbedForm,
	TabbedFormTabs,
} from "react-admin"
import { EnigmaCommonData } from "./enigmaCommonData/enigmaCommonData"
import { EnigmaCaption } from "./enigmaCaption/enigmaCaption"
import { EnigmaIntroOutro } from "./enigmaIntroOutro/enigmaIntroOutro"
import { EnigmaClue } from "./enigmaClues/enigmaClue"
import { EnigmaCaptionClue } from "./enigmaClues/enigmaCaptionClue"
import { EnigmaTemporalClue } from "./enigmaClues/enigmaTemporalClue"
import { EnigmaExplanation } from "./enigmaExplanation/enigmaExplanation"
import { EnigmaEvents } from "./enigmaEvents/enigmaEvents"
import { FormToolbar } from "../components/button"

export const EnigmaCreate = (props) => {
	return (
		<Create {...props}>
			<TabbedForm
				tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
				toolbar={<FormToolbar />}
			>
				<EnigmaCommonData {...props} />
				<EnigmaIntroOutro />
				<EnigmaCaption />
				<EnigmaExplanation />
				<EnigmaClue />
				<EnigmaCaptionClue />
				<EnigmaTemporalClue />
				<EnigmaEvents />
			</TabbedForm>
		</Create>
	)
}
