import { makeStyles } from "@material-ui/core"

/* just a small 'hack' to apply RaFormInput styles on other things than react-admin FormInput (because when including Input components from other components, react admin doesn't apply styles correctly, since it relies on cloning children for us sometimes) */
export const useFormInputStyles = makeStyles({
	input: {
		width: "100%",
		maxWidth: "40rem",
		display: "flex"
	},
	smallInput: {
		transform: 'scale(0.8)',
		transformOrigin: 'left center'
	},
	hidden: {
		display: "none"
	}
},
    { name: 'RaFormInputWrapper' }
);
