import { TextInput } from "react-admin"
import { useFormInputStyles } from "../../utils/useFormInputStyles"
import { MediaField } from "../../components/mediaField"
import { Grid } from "@material-ui/core"

export const EnigmaFacebookData = () => {
	const classes = useFormInputStyles()

	return (
		<>
			<TextInput
				className={classes.input}
				minRows="3"
				multiline
				source="data.content"
				label="Contenu du post Facebook"
			/>
			<MediaField
				className={classes.input}
				source="data.media"
				label="Image du post Facebook"
				helperText="⚠ ne pas faire un screenshot d'un post Facebook : uploader uniquement une image"
				alt={true}
				altClassname={classes.input}
				altSource="data.media.alt"
				altLabel="Description textuelle de l'image (pour personnes aveugles ou malvoyantes)"
				credit={true}
				creditSource="data.media.credit"
				creditLabel="Crédit de l'image"
				creditClassname={classes.input}
			/>

			<TextInput
				className={classes.input}
				source="data.author"
				label="Auteur du post Facebook"
			/>

			<MediaField
				className={classes.input}
				styleVariant="avatar"
				source="data.avatar"
				label="Avatar de l'auteur"
				helperText="Affiché dans une bulle à côté du nom de l'auteur"
			/>

			<TextInput
				className={classes.input}
				source="data.shares"
				label="Nombre de partages"
			/>
			<TextInput
				className={classes.input}
				source="data.likes"
				label="Nombre de likes"
			/>
			<TextInput
				className={classes.input}
				source="data.comments"
				label="Nombre de commentaires"
			/>

			<TextInput
				className={[classes.input, classes.smallInput]}
				source="data.source"
				label="URL du post Facebook"
				type="url"
				helperText="Actuellement pas utilisé dans le jeu, renseignable pour garder une trace utile dans la saisie d'énigmes"
			/>
		</>
	)
}
