import { makeStyles } from "@material-ui/core"
import {
	ArrayInput,
	FormTab,
	ReferenceInput,
	SelectInput,
	SimpleFormIterator,
	TextInput
} from "react-admin"
import { getCustomAddButton, getCustomRemoveButton } from "../../components/button"
import { useFormInputStyles } from "../../utils/useFormInputStyles"
import { TabHeader } from "../../utils/TabHeader"


export const EnigmaTemporalClue = (props) => {
	const classes = useFormInputStyles()

	return (
		<FormTab
			label="Aide temps écoulé"
			{...props}
		>
			<TabHeader
				title="Messages d'aide au joueur quand il met du temps à trouver"
				desc={
					<p>
						Quand le joueur bloque sur une énigme, un message d'aide peut être déclenché.
					</p>
				}
			/>
			<TextInput
				className={classes.hidden}
				disabled
				source="temporalClue.id"
				label="Id de l'indice"
			/>
			<TextInput
				className={classes.hidden}
				disabled
				source="temporalClue.advice.id"
				label="Id de la réponse"
			/>
			<ReferenceInput
				source="temporalClue.advice.character"
				reference="characters"
				label="Personnage aidant le joueur"
				className={classes.textarea}
			>
				<SelectInput optionText="name" />
			</ReferenceInput>
			<TextInput
				className={classes.textarea}
				multiline
				minRows="2"
				source="temporalClue.advice.content"
				label="Message d'aide donné"
			/>
			<ArrayInput
				name="answers"
				source="temporalClue.advice.answers"
				label="Retours possibles du joueur suite à l'aide donnée"
				className={"Override-noLabelShrink"}
			>
				<SimpleFormIterator
					disableReordering
					addButton={getCustomAddButton("Ajouter une réponse du joueur")}
					removeButton={getCustomRemoveButton("Supprimer")}
				>
					<TextInput
						className={classes.hidden}
						disabled
						source="id"
						label="Id de la réponse"
					/>
					<TextInput
						className={classes.textarea}
						source="text"
						label="Texte"
					/>
				</SimpleFormIterator>
			</ArrayInput>
		</FormTab>
	)
}
