import { TextInput } from "react-admin"
import { useFormInputStyles } from "../../utils/useFormInputStyles"
import { MediaField } from "../../components/mediaField"


export const EnigmaTiktokData = () => {
	const classes = useFormInputStyles()

	return (
		<>
			<MediaField
				className={classes.input}
				source="data.media"
				label="Vidéo TikTok au format .mp4"
				helperText="⚠ ne pas faire un enregistrement d'écran d'une vidéo Tiktok : uploader uniquement la vidéo. Si besoin, utilisez un outil de conversion vidéo Tiktok vers MP4 et uploadez la vidéo"
			/>
			<TextInput
				className={classes.input}
				source="data.author"
				label="Auteur de la vidéo"
				helperText="Exemple : « @villeangers ». ⚠ pas « La ville d'Angers »"
			/>
			<MediaField
				className={classes.input}
				styleVariant="avatar"
				source="data.avatar"
				label="Avatar de l'auteur"
				helperText="Affiché dans une bulle sur le côté de la vidéo"
			/>
			<TextInput
				className={classes.input}
				source="data.likes"
				label="Nombre de likes"
			/>
			<TextInput
				className={classes.input}
				source="data.shares"
				label="Nombre de partages"
			/>
			<TextInput
				className={classes.input}
				source="data.comments"
				label="Nombre de commentaires"
			/>
		</>
	)
}
