import * as React from "react"
import { Create, SimpleForm } from "react-admin"
import { makeStyles } from "@material-ui/core"

import { FormToolbar } from "../components/button"
import { MediaField } from "../components/mediaField"
import { useFormInputStyles } from "../utils/useFormInputStyles"


export const AvatarCreate = (props) => {
	const classes = useFormInputStyles()

	return <Create {...props}>
		<SimpleForm toolbar={<FormToolbar />}>
			<MediaField
				label="Avatar"
				className={classes.input}
				styleVariant="avatar"
				source="media"
				accept="image/*"
				alt={true}
				altSource="media.alt"
				altLabel="Description textuelle de l'avatar (pour personnes aveugles ou malvoyantes)"
				altClassname={classes.input}
			/>
		</SimpleForm>
	</Create>
}
