import {
	FileField,
	FileInput,
	FormDataConsumer,
	TextInput
} from "react-admin"
import { get } from "lodash"
import * as React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useFormInputStyles } from "../utils/useFormInputStyles"

const useStyles = makeStyles({
	image: {
		maxWidth: props => props.styleVariant === "avatar" ? "60px" : "300px",
		borderRadius: props => props.styleVariant === "avatar" ? "50%" : "0",
		display: props => props.styleVariant === "avatar" ? undefined : "block"
	},
	video: {
		maxWidth: "300px",
	},
	audio: {
		maxWidth: "300px",
	},
	fileInput: {
		'& p': {
			margin: 0,
			fontSize: '0.8rem',
			textAlign: 'left'
		}
	}
})

export const MediaField = (props) => {
	const classes = useStyles(props)
	const inputClasses = useFormInputStyles()

	return (
		<>
				<FileInput
					source={props.source}
					helperText={props.helperText}
					label={props.label}
					defaultValue={props.value}
					onChange={props.onChange}
					name={props.name}
					accept={props.accept || "image/*,audio/mpeg,video/mp4"}
					className={[props.className, classes.fileInput]}
				>
					<FileField target="_blank" source="url" title="voir l'image en grand format" />
				</FileInput>
				<FormDataConsumer>
					{({ formData }) => {
						const media = props.value ? get(props.value, props.id) : get(formData, props.source)
						if (media) {
							if ((media.rawFile || {}).type === "video/mp4" || media.type === "video") {
								return (
									<>
										<video controls src={media.url} className={classes.video}></video>
									</>
								)
							}
							else if ((media.rawFile || {}).type === "audio/mpeg" || media.type === "audio") {
								return (
									<>
										<audio controls src={media.url} className={classes.audio}></audio>
									</>
								)
							} else {
								return (
									<>
										<img src={media.url} alt={media.title} className={classes.image} />
									</>
								)
							}
						}
					}}
				</FormDataConsumer>
			{props.credit && (
				<TextInput
					className={[props.creditClassname, inputClasses.smallInput]}
					source={props.creditSource}
					label={props.creditLabel || "Crédit"}
					helperText={false}
					size="small"
				/>
			)}
			{props.alt && (
				<TextInput
					className={[props.altClassname, inputClasses.smallInput]}
					source={props.altSource}
					label={props.altLabel || "Description textuelle (pour personnes aveugles ou malvoyantes)"}
					helperText={false}
					size="small"
				/>
			)}
		</>
	)
}
