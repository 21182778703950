import {
	ArrayInput,
	FormTab,
	ReferenceInput,
	SelectInput,
	SimpleFormIterator,
	TextInput
} from "react-admin"
import { getCustomAddButton } from "../../components/button"
import { MediaField } from "../../components/mediaField"
import { useFormInputStyles } from "../../utils/useFormInputStyles"
import { TabHeader } from "../../utils/TabHeader"


export const EnigmaExplanation = (props) => {
	const classes = useFormInputStyles()

	return (
		<FormTab label="Explication" {...props}>
			<TabHeader
				title="Messages d'explication"
				desc={
					<p>
						Messages affichés lors de la résolution d'une énigme pour expliquer au joueur ce qu'il fallait faire.
						<br />
						<br />
						⚠ Pour le cas d'une vraie actu, le 1er message d'explication affiché est toujours celui liée à la légende choisie par le joueur (renseignée dans l'onglet Chronique).
						<br />Les messages décrits ci-dessous sont affichés ensuite.
					</p>
				}
			/>
			<ArrayInput
				name="explanations"
				source="explanations"
				label="Explications"
				className={"Override-noLabelShrink"}
			>
				<SimpleFormIterator disableReordering addButton={getCustomAddButton("Ajouter un message d'explication")}>
					<TextInput
						className={classes.hidden}
						disabled
						source="id"
						label="Id du message"
					/>
					<ReferenceInput
						source="character"
						reference="characters"
						label="Personnage"
						className={classes.textarea}
					>
						<SelectInput optionText="name" />
					</ReferenceInput>
					<TextInput
						className={classes.textarea}
						multiline
						minRows="3"
						source="content"
						label="Message texte (ou alternative textuelle du média ci-dessous)"
						helperText="si votre explication est un vocal, écrivez le texte correspondant ici pour que le joueur puisse le lire en plus de l'écouter."
					/>
					<MediaField
						source="mediaContent"
						label="Média (image, vidéo .mp4 ou fichier audio .mp3)"
						className={classes.textarea}
						helperText="⚠ le média remplace le message texte renseigné. Pour ajouter une photo que vous décrivez dans un message juste avant, ajoutez d'abord une explication texte, puis une explication image."
					/>
				</SimpleFormIterator>
			</ArrayInput>
		</FormTab>
	)
}
