import { useFormInputStyles } from "../../utils/useFormInputStyles"
import { MediaField } from "../../components/mediaField"


export const EnigmaVideoData = () => {
	const classes = useFormInputStyles()

	return (
		<>
			<MediaField
				className={classes.input}
				source="data.media"
				label="Vidéo au format .mp4"
				helperText="Si besoin, utilisez un outil de conversion vidéo Youtube ou autre vers MP4 et uploadez la vidéo"
			/>
		</>
	)
}
