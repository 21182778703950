import { Typography } from "@mui/material"

export const TabHeader = ({title, desc}) => {
	return (
		<>
			<Typography variant="h6" marginY={1}>{title}</Typography>
			{!!desc && <Typography variant="body2" marginBottom={2}>{desc}</Typography>}
		</>
	)
}
