import { TextInput } from "react-admin"
import { useFormInputStyles } from "../../utils/useFormInputStyles"
import { MediaField } from "../../components/mediaField"

export const EnigmaArticleData = () => {
	const classes = useFormInputStyles()
	return (
		<>
			<TextInput
				className={classes.input}
				multiline
				source="data.articleTitle"
				label="Titre de l'article"
			/>

			<TextInput
				className={classes.input}
				source="data.author"
				label="Auteur de l'article"
				helperText="Affiché juste en dessous du titre de l'article"
			/>

			<TextInput
				className={classes.input}
				minRows="5"
				multiline
				source="data.content"
				label="Contenu de l'article"
				helperText="Extrait affiché en corps de texte de l'énigme"
			/>

			<TextInput
				source="data.source"
				label="URL de l'article"
				type="url"
				helperText="Lien affiché en bas de l'extrait de l'article (« source: … »)"
			/>

			<MediaField
				className={classes.input}
				source="data.media"
				label="Photo de l'article"
				helperText="⚠ ne pas faire un screenshot d'un article sur le web : uploader uniquement une image"
				alt={true}
				altClassname={classes.input}
				altSource="data.media.alt"
				altLabel="Description textuelle de la photo (pour personnes aveugles ou malvoyantes)"
			/>
		</>
	)
}
