import * as React from "react"
import { Create, SimpleForm, TextInput } from "react-admin"
import { makeStyles } from "@material-ui/core"

import { FormToolbar } from "../components/button"
import { MediaField } from "../components/mediaField"


const useStyles = makeStyles({
	textarea: {
		display: "flex",
		width: "25%"
	}
})

export const CharacterCreate = (props) => {
	const classes = useStyles()

	return <Create {...props}>
		<SimpleForm toolbar={<FormToolbar />}>
			<TextInput
				label="nom du personnage"
				source="name"
				className={classes.textarea}
			/>
			<MediaField
				styleVariant="avatar"
				label="image du personnage"
				className={classes.textarea}
				source="media"
				alt={true}
				altSource="media.alt"
				altLabel="Description du personnage"
				altClassname={classes.textarea}
			/>
		</SimpleForm>
	</Create>
}
