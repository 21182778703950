import { InputLabel } from "@material-ui/core"
import {
	ArrayInput,
	FormTab,
	RadioButtonGroupInput,
	ReferenceInput,
	SelectInput,
	SimpleFormIterator,
	TextInput
} from "react-admin"
import { getCustomAddButton } from "../../components/button"
import { MediaField } from "../../components/mediaField"
import { TabHeader } from "../../utils/TabHeader"
import { useFormInputStyles } from "../../utils/useFormInputStyles"

export const EnigmaCaption = (props) => {
	const classes = useFormInputStyles()

	return (
		<FormTab label="Chronique" {...props}>
			<TabHeader
				title="Chronique"
				desc={
					<p>
						Quand un joueur pense qu'une actu est vraie, il prépare ensuite l'actu pour sa chronique. <br/>
						On lui montre l'image de l'actu qui sera publiée dans sa chronique, et on lui propose de choisir sa légende parmi trois propositions.
					</p>
				}
			/>
			<InputLabel>Message déclenché lorsque le joueur a indiqué que l'actu est vraie</InputLabel>

			<TextInput
				className={classes.hidden}
				disabled
				source="caption.id"
				label="Id du dialogue"
			/>

			<ReferenceInput
				source="caption.character"
				reference="characters"
				label="Personnage"
			>
				<SelectInput
					optionText="name"
					helperText="La plupart du temps, c'est Sasha qui engage la conversation"
				/>
			</ReferenceInput>
			<TextInput
				multiline
				minRows="2"
				name="caption.content"
				label="Message"
				source="content"
			/>

			<br />

			<MediaField
				name="caption.media"
				source="caption.media"
				label="Image pour la chronique (affichée au joueur pendant qu'il choisit une légende)"
				credit={true}
				creditLabel="Crédit de l'image"
				creditClassname={classes.input}
				creditSource="caption.media.credit"
				alt={true}
				altLabel="Description textuelle de l'image (pour personnes aveugles ou malvoyantes)"
				altClassname={classes.input}
				altSource="caption.media.alt"
			/>

			<br />

			<ArrayInput
				name="caption.choices"
				source="caption.choices"
				label="Légendes (3 légendes nécessaires, dont une vraie pour les vraies actus)"
				className={"Override-noLabelShrink"}
			>
				<SimpleFormIterator disableReordering addButton={getCustomAddButton("Ajouter une légende")}>
					<TextInput
						className={classes.hidden}
						disabled
						source="id"
						label="Id de la légende"
					/>
					<TextInput
						minRows="3"
						multiline
						source="content"
						label="Texte de la légende"
					/>
					<RadioButtonGroupInput
						source="real"
						label="Vraie ou fausse ?"
						choices={[
							{ id: true, name: "vraie légende" },
							{ id: false, name: "fausse légende" }
						]}
					/>

					<TextInput
						minRows="3"
						multiline
						source="feedback.content"
						label="Message d'explication suite à sélection de cette légende"
						helperText="1er message qui apparaît sur l'écran d'explication de résolution d'une énigme. Le reste (commun à tous les choix du joueur) est à renseigner dans l'onglet Explication."
					/>
					<ReferenceInput
						source="character"
						reference="characters"
						label="Personnage écrivant le message d'explication"
					>
						<SelectInput optionText="name" />
					</ReferenceInput>
				</SimpleFormIterator>
			</ArrayInput>
		</FormTab>
	)
}
