import { FormTab, ReferenceInput, SelectInput, TextInput } from "react-admin"
import { useFormInputStyles } from "../../utils/useFormInputStyles"
import { TabHeader } from "../../utils/TabHeader"

export const EnigmaIntroOutro = (props) => {
	const classes = useFormInputStyles()

	return (
		<FormTab label="Intro & Outro" {...props}>
			<TabHeader
				title="Message d'intro"
				desc="Message qui apparait avant l'affichage de l'énigme dans le fil de discussion du joueur. ⚠ Le personnage parlant de l'énigme est le même que celui choisi ici."
			/>
			<ReferenceInput
				source="intro.character"
				reference="characters"
				label="Personnage"
			>
				<SelectInput
					optionText="name"
					helperText="C'est pratiquement toujours Léo qui introduit l'énigme"
				/>
			</ReferenceInput>
			<TextInput
				className={classes.hidden}
				disabled
				source="intro.id"
				label="Id du message"
			/>
			<TextInput
				multiline
				minRows="2"
				source="intro.content"
				label="Message"
			/>
			<TabHeader
				title="Message d'outro"
				desc="Message qui apparait juste après l'affichage de l'énigme dans le fil de discussion du joueur."
			/>
			<ReferenceInput
				source="outro.character"
				reference="characters"
				label="Personnage"
			>
				<SelectInput
					optionText="name"
					helperText="Il est préférable que le personnage soit le même que celui de l'intro"
				/>
			</ReferenceInput>
			<TextInput
				className={classes.hidden}
				disabled
				source="outro.id"
				label="Id du message"
			/>
			<TextInput
				multiline
				minRows="2"
				source="outro.content"
				label="Message"
			/>
		</FormTab>
	)
}
