import * as React from "react"
import { Edit, SimpleForm, TextInput } from "react-admin"
import { makeStyles } from "@material-ui/core"
import { MediaField } from "../components/mediaField"
import { FormToolbar } from "../components/button"
import { useFormInputStyles } from "../utils/useFormInputStyles"


export const AvatarEdit = (props) => {
	const classes = useFormInputStyles()

	return (
		<Edit {...props}>
			<SimpleForm toolbar={<FormToolbar delete={true} />}>
				<TextInput className={classes.hidden} disabled source="id" />
				<MediaField
					label="Avatar"
					styleVariant="avatar"
					className={classes.input}
					source="media"
					accept="image/*"
					alt={true}
					altSource="media.alt"
					altLabel="Description textuelle de l'avatar (pour personnes aveugles ou malvoyantes)"
					altClassname={classes.input}
				/>
			</SimpleForm>
		</Edit>
	)
}
