import { useFormInputStyles } from "../../utils/useFormInputStyles"
import { MediaField } from "../../components/mediaField"


export const EnigmaPhotoData = () => {
	const classes = useFormInputStyles()

	return (
		<>
			<MediaField
				className={classes.input}
				source="data.media"
				label="Photo"
				alt={true}
				altClassname={classes.input}
				altSource="data.media.alt"
				altLabel="Description textuelle de la photo (pour personnes aveugles ou malvoyantes)"
				credit={true}
				creditClassname={classes.input}
				creditSource="data.media.credit"
				creditLabel="Crédit de la photo"
			/>
		</>
	)
}
