import {
	AutocompleteArrayInput,
	FormTab,
	ReferenceArrayInput,
	ArrayInput,
	SimpleFormIterator,
	TextInput
} from "react-admin"
import { getCustomAddButton } from "../../components/button"
import { TabHeader } from "../../utils/TabHeader"

export const EnigmaEvents = (props) => {
	return (
		<FormTab label="Événements" {...props}>
			<TabHeader
				title="Événements"
				desc={
					<p>
						Il est possible de déclencher des événements spécifiques quand l'énigme s'affiche.
						<br /><br />
						⚠ Utilisation avancée.
					</p>
				}
			/>
			<ReferenceArrayInput
				name="events.messages"
				source="events.messages"
				reference="chats"
				helperText="Messages affichés au joueur quand l'énigme s'affiche"
				label="Messages"
			>
				<AutocompleteArrayInput optionText="content" />
			</ReferenceArrayInput>
			<ArrayInput
				source="events.customs"
				label="Evènements personnalisés"
				className={"Override-noLabelShrink"}
			>
				<SimpleFormIterator disableReordering addButton={getCustomAddButton("Ajouter un événement personnalisé")}>
					<TextInput label="Evènement personnalisé" />
				</SimpleFormIterator>
			</ArrayInput>
		</FormTab>
	)
}
