import { useFormInputStyles } from "../../utils/useFormInputStyles"
import { DateInput, RadioButtonGroupInput, TextInput } from "react-admin"
import { MediaField } from "../../components/mediaField"

export const EnigmaInstagramData = () => {
	const classes = useFormInputStyles()

	return (
		<>
			<MediaField
				className={classes.input}
				source="data.media"
				label="Image du post Instagram"
				helperText="⚠ ne pas faire un screenshot d'un post Instagram : uploader uniquement une image"
				alt={true}
				altClassname={classes.input}
				altSource="data.media.alt"
				altLabel="Description textuelle de l'image (pour personnes aveugles ou malvoyantes)"
			/>
			<TextInput
				className={classes.input}
				minRows="3"
				multiline
				source="data.content"
				label="Texte du post Instagram"
			/>
			<TextInput
				className={classes.input}
				source="data.author"
				label="Auteur du post Instagram"
			/>

			<MediaField
				className={classes.input}
				styleVariant="avatar"
				source="data.avatar"
				label="Avatar de l'auteur"
				helperText="Affiché dans une bulle à côté du nom de l'auteur"
			/>
			<RadioButtonGroupInput
				className={classes.input}
				source="data.certified"
				label="Compte certifié ?"
				helperText="Si oui, un badge certifié est affiché à côté du nom de l'auteur."
				choices={[
					{ id: true, name: "oui" },
					{ id: false, name: "non" }
				]}
			/>
			<TextInput
				className={classes.input}
				source="data.likes"
				label="Nombre de Likes"
			/>
			<DateInput
				className={[classes.input, classes.smallInput]}
				source="data.date"
				label="Date"
				helperText="Actuellement pas utilisé dans le jeu, renseignable pour garder une trace utile dans la saisie d'énigmes"
			/>
			<TextInput
				className={[classes.input, classes.smallInput]}
				source="data.source"
				label="URL du post Instagram"
				type="url"
				helperText="Actuellement pas utilisé dans le jeu, renseignable pour garder une trace utile dans la saisie d'énigmes"
			/>
		</>
	)
}
