import {
	ArrayInput,
	FormTab,
	ReferenceInput,
	SelectInput,
	SimpleFormIterator,
	TextInput
} from "react-admin"
import { getCustomAddButton, getCustomRemoveButton } from "../../components/button"
import { useFormInputStyles } from "../../utils/useFormInputStyles"
import { TabHeader } from "../../utils/TabHeader"


export const EnigmaCaptionClue = (props) => {
	const classes = useFormInputStyles()

	return (
		<FormTab label="Aide légende" {...props}>
			<TabHeader
				title="Messages d'aide au joueur pendant le choix de légende"
				desc={
					<p>
						Quand le joueur doit choisir la légende d'une actu qu'il pense vraie, il peut demander de l'aide à Sasha.<br /><br />Décrivez ici la question qu'il peut poser, la réponse qu'il peut obtenir, et ce qu'il peut dire suite à cette réponse.
					</p>
				}
			/>
			<TextInput
				className={classes.hidden}
				disabled
				source="captionClue.id"
				label="Id de l'indice"
			/>
			<TextInput
				multiline
				minRows="3"
				source="captionClue.request"
				label="Demande du joueur"
			/>
			<TextInput
				className={classes.hidden}
				disabled
				source="captionClue.advice.id"
				label="Id de la réponse"
			/>
			<ReferenceInput
				source="captionClue.advice.character"
				reference="characters"
				label="Personnage qui donne l'aide"
			>
				<SelectInput optionText="name" helperText="Il est conseillé que ce soit Sasha, car c'est à elle que le joueur demande de l'aide" />
			</ReferenceInput>
			<TextInput
				multiline
				minRows="3"
				source="captionClue.advice.content"
				label="Message d'aide donné"
			/>
			<ArrayInput
				name="answers"
				source="captionClue.advice.answers"
				label="Retours possibles du joueur suite à l'aide donnée"
				className={"Override-noLabelShrink"}
			>
				<SimpleFormIterator
					disableReordering
					addButton={getCustomAddButton("Ajouter une réponse du joueur")}
					removeButton={getCustomRemoveButton("Supprimer")}
				>
					<TextInput
						className={classes.hidden}
						disabled
						source="id"
						label="Id de la réponse"
					/>
					<TextInput
						className={classes.textarea}
						multiline
						source="text"
						label="Texte"
					/>
				</SimpleFormIterator>
			</ArrayInput>
		</FormTab>
	)
}
