import { RadioButtonGroupInput, TextInput } from "react-admin"
import { useFormInputStyles } from "../../utils/useFormInputStyles"
import { MediaField } from "../../components/mediaField"

export const EnigmaTweetData = () => {
	const classes = useFormInputStyles()

	return (
		<>
			<TextInput
				className={classes.input}
				source="data.source"
				label="URL du tweet"
				type="url"
				helperText="Lien complet vers le tweet. Utilisé en interne pour déduire le compte « @ » de l'auteur et l'afficher"
			/>
			<TextInput
				className={classes.input}
				minRows="3"
				multiline
				source="data.content"
				label="Texte du tweet"
			/>
			<MediaField
				className={classes.input}
				source="data.media"
				label="Image du tweet"
				helperText="⚠ ne pas faire un screenshot d'un tweet : uploader uniquement une image"
				alt={true}
				altClassname={classes.input}
				altSource="data.media.alt"
				altLabel="Description textuelle de l'image (pour personnes aveugles ou malvoyantes)"
				credit={true}
				creditSource="data.media.credit"
				creditLabel="Crédit de l'image"
				creditClassname={classes.input}
			/>

			<TextInput
				className={classes.input}
				source="data.author"
				label="Auteur du tweet"
				helperText="Exemple : « Report'Cité ». ⚠ pas « @reportcite »"
			/>

			<RadioButtonGroupInput
				className={classes.input}
				source="data.certified"
				label="Compte certifié ?"
				helperText="Si oui, un badge Twitter certifié est affiché à côté du nom de l'auteur."
				choices={[
					{ id: true, name: "oui" },
					{ id: false, name: "non" }
				]}
			/>

			<MediaField
				className={classes.input}
				styleVariant="avatar"
				source="data.avatar"
				label="Avatar de l'auteur"
				helperText="Affiché dans une bulle à côté du nom de l'auteur"
			/>

			<TextInput
				className={classes.input}
				source="data.retweets"
				label="Nombre de retweets"
			/>

			<TextInput
				className={classes.input}
				source="data.likes"
				label="Nombre de likes"
			/>

			<TextInput
				className={classes.input}
				source="data.comments"
				label="Nombre de commentaires"
			/>
		</>
	)
}
