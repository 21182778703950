import { useState } from "react"
import { FormTab,
	RadioButtonGroupInput,
	required,
	TextInput,
	SelectInput,
	number,
	AutocompleteArrayInput,	ReferenceArrayInput,
	FormDataConsumer
} from "react-admin"
import { EnigmaArticleData } from "../enigmaSpecificData/enigmaArticleData"
import { EnigmaTweetData } from "../enigmaSpecificData/enigmaTweetData"
import { EnigmaFacebookData } from "../enigmaSpecificData/enigmaFacebookData"
import { EnigmaInstagramData } from "../enigmaSpecificData/enigmaInstagramData"
import { EnigmaTiktokData } from "../enigmaSpecificData/enigmaTiktokData"
import { EnigmaPhotoData } from "../enigmaSpecificData/enigmaPhotoData"
import { EnigmaVideoData } from "../enigmaSpecificData/enigmaVideoData"
import { TabHeader } from "../../utils/TabHeader"
import { useFormInputStyles } from "../../utils/useFormInputStyles"

export const EnigmaCommonData = (props) => {
	const classes = useFormInputStyles()
	return (
		<FormTab label="Énigme" {...props}>
			<TabHeader title="Énigme" desc="Informations importantes de l'énigme et contenu de la (peut-être fake) news"/>
			<TextInput
				validate={[required()]}
				multiline
				source="title"
				label="Titre"
			/>
			<RadioButtonGroupInput
				label="État de l'énigme"
				validate={required()}
				source="active"
				defaultValue={true}
				className={classes.input}
				helperText={`Note : une énigme incomplète n'est jamais incluse dans le jeu, même quand "activée". Vous pouvez laisser activées les énigmes en cours de rédaction, et désactiver uniquement les énigmes que vous ne souhaitez pas voir apparaître dans le jeu.`}
				choices={[
					{ id: true, name: "Activée" },
					{ id: false, name: "Désactivée" }
				]}
			/>
			<br />
			<RadioButtonGroupInput
				label="Véracité de l'information"
				validate={required()}
				source="real"
				choices={[
					{ id: true, name: "Vraie info" },
					{ id: false, name: "Fake news" }
				]}
			/>
			<SelectInput
				source="level"
				label="Niveau de difficulté"
				validate={[number(), required()]}
				allowEmpty
				choices={[
					{ id: 1, name: 'facile (1)' },
					{ id: 2, name: 'moyen (2)' },
					{ id: 3, name: 'difficile (3)' },
					{ id: 4, name: 'aucun - énigme affichée à tous les niveaux (4)' },
				]}
				helperText="Ce choix influe sur les énigmes que voit le joueur suivant la difficulté qu'il choisit."
			/>
			<br/>
			<ReferenceArrayInput
				source="events.toolbox"
				reference="toolbox"
				label="Outils liés"
				helperText="Outils débloqués en jeu quand cette énigme apparaît"
			>
				<AutocompleteArrayInput optionText="title" />
			</ReferenceArrayInput>
			<br/>
			<br/>
			<TabHeader title="Description" />
			<SelectInput
				label="Type"
				source="type"
				allowEmpty
				validate={required()}
				choices={[
					{ id: "website", name: "Article Web" },
					{ id: "tweet", name: "Tweet" },
					{ id: "facebook", name: "Post Facebook" },
					{ id: "instagram", name: "Post Instagram" },
					{ id: "tiktok", name: "Vidéo TikTok" },
					{ id: "photo", name: "Photo" },
					{ id: "video", name: "Vidéo" },
				]}
			/>
			<FormDataConsumer>
                 {({ formData, ...rest }) => {
					switch (formData.type) {
						case "website":
							return <EnigmaArticleData {...rest} />
						case "tweet":
							return <EnigmaTweetData {...rest} />
						case "facebook":
							return <EnigmaFacebookData {...rest} />
						case "instagram":
							return <EnigmaInstagramData {...rest} />
						case "tiktok":
							return <EnigmaTiktokData {...rest} />
						case "photo":
							return <EnigmaPhotoData {...rest} />
						case "video":
							return <EnigmaVideoData {...rest} />
						default:
							return null
					}
				}}
			</FormDataConsumer>
		</FormTab>
	)
}
